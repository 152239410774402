<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div v-if="list && list.length > 0" style="max-height: 75vh; overflow-y: scroll">
    <CustomTable
      v-model="value"
      :items="items"
      :fields="fields"
      :add_new_button="false"
      :sort_button="false"
      :paging="false"
      :per_page="999999"
    />
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>
<script>
import general from '@/mixins/index'

export default {
  mixins: [general],
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    list: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        { key: 'checkbox', label: '#', sortable: true },
        { key: 'no', label: 'No', sortable: true },
        { key: 'avatar', label: 'Avatar', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'price', label: 'Price', sortable: true },
        { key: 'stock', label: 'Stock', sortable: true },
      ],
      items: [],
    }
  },
  watch: {
    list(val) {
      if (val) {
        this.items = val
      }
    },
  },
  mounted() {
    this.items = this.list
  },
  methods: {
    handlerSelect() {
      this.$emit('handlerSelect', this.selectedList)
    },
  },
}
</script>
