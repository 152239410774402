<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-list-group v-if="items && items.length > 0" flush>
    <b-list-group-item v-for="(item, index) in items" :key="index">
      <span
        :class="{ active: item.slug === category_name }"
        @click="handlerChangeCategory(item, 0)"
      >
        + {{ item.name }}
      </span>
      <b-list-group>
        <b-list-group-item
          v-for="(i, idx) in item.children"
          :key="idx"
          class="border-0 pb-0"
        >
          <span
            :class="{ active: i.slug === category_name }"
            @click="handlerChangeCategory(i, 1)"
          >
            + {{ i.name }}
          </span>
          <b-list-group>
            <b-list-group-item
              v-for="(y, idy) in i.children"
              :key="idy"
              class="border-0 pb-0"
            >
              <span
                :class="{ active: y.slug === category_name }"
                @click="handlerChangeCategory(y, 2)"
              >
                {{ y.name }}
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-list-group-item>
      </b-list-group>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

export default {
  components: {
    BListGroup,
    BListGroupItem,
  },
  mixins: [general],
  data() {
    return {
      items: [],
      category_name: null,
    }
  },
  mounted() {
    this.loadList()
    if (this.$route.query.category_name !== undefined) {
      this.category_name = this.$route.query.category_name
    }
  },
  methods: {
    handlerChangeCategory(item, index) {
      this.category_name = item.slug
      // this.$router.push(
      //   `/promotion/add?category_id=${item.id}&category_name=${item.slug}`,
      // )
      this.$emit('handlerChangeCategory', { item, index })
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.items = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item a {
  color: #6e6b7b;
}
.list-group-item:hover {
  cursor: pointer;
}
.list-group-item a.active {
  color: var(--primary);
}
.sortable-ghost .list-group-item,
.sortable-ghost {
  background: var(--primary);
  color: #ffffff;
}
.list-group-item .list-group-item {
  background: transparent;
}
</style>
