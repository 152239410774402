<!-- eslint-disable vue/html-self-closing -->
<template>
  <!-- eslint-disable vue/max-attributes-per-line */ -->
  <!-- eslint-disable vue/html-indent -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="couponinfo" :title="$t('Coupon Information')">
            <b-form-group :label="$t('Coupon name')+`:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Coupon name"
                rules="required"
              >
                <b-form-input
                  v-model="model.name"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Coupon name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="$t('Coupon code')+`:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Coupon code"
                :rules="{ required: true, regex: /^[A-Za-z0-9]+$/ }"
              >
                <div class="d-flex flex-wrap align-items-center">
                  <b-form-input
                    v-model="model.code"
                    :state="errors.length > 0 ? false : null"
                    :class="{'disabled': model.code_type === 1}"
                    placeholder="Coupon code"
                    style="max-width: 300px;"
                    @change="handleCode"
                  />
                  <b-form-checkbox
                    v-model="model.code_type"
                    checked="true"
                    class="custom-control-success ml-1"
                    name="check-button"
                    switch
                    :value="1"
                    :unchecked-value="2"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="text-nowrap">{{ model.code_type === 1 ? $t('Auto code') : $t('Code by seller') }}</span>
                  </b-form-checkbox>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('From date') +`:`"
                  label-cols-md="12"
                >
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="model.date_from"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:ss',
                      }"
                      placeholder="Choose a date"
                    />
                  </b-input-group>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="date-from"
                  >
                    <input id="date-from" v-model="model.date_from" type="hidden">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('To date') +`:`"
                  label-cols-md="12"
                >
                  <b-input-group
                    class="input-group-merge"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="model.date_to"
                      class="form-control"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i:ss',
                      }"
                      placeholder="Choose a date"
                    />
                  </b-input-group>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="date-to"
                  >
                    <input id="date-to" v-model="model.date_to" type="hidden">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-form-group
                :label="$t('Currency for coupon') +`:`"
                label-cols-md="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Currency"
                  rules="required"
                >
                  <v-select
                    v-if="currencyOptionsDefault"
                    v-model="currencyDefault"
                    label="display_name"
                    :options="currencyOptionsDefault"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template #option="{ iso_code, name, img }">
                      <img
                        :src="img"
                        :alt="iso_code"
                        style="width: 15px; height: 15px; object-fit: contain;"
                        class="mr-2"
                      />
                      <span>{{ iso_code }} ({{ name }})</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group> -->
            <b-form-group :label="$t('Discount type')+`:`" label-cols-md="12">
              <b-row>
                <b-col md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Is active"
                    rules="required"
                  >
                    <div class="d-flex align-items-center">
                      <b-form-radio v-model="model.type" :value="1">
                        {{ $t('According to the amount') }}
                      </b-form-radio>
                      <b-form-radio
                        v-model="model.type"
                        :value="2"
                        class="ml-3"
                      >
                        {{ $t('Set % discount on total order') }} (%)
                      </b-form-radio>
                      <b-form-radio
                        v-model="model.type"
                        :value="3"
                        class="ml-3"
                      >
                        Freeship
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col md="12" class="mt-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Discount money"
                    :rules="model.type != 3 ? 'required': ''"
                  >
                    <b-form-input
                      v-if="model.type === 1"
                      v-model="model.discount_money"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <!-- <cleave
                      v-if="model.type === 1"
                      v-model="model.discount_money"
                      class="form-control"
                      :raw="false"
                      :options="cleaveOptions.number"
                    /> -->
                    <b-input-group
                      v-if="model.type === 2"
                      append="%"
                    >
                      <b-form-input
                        v-model="model.discount_money"
                        min="1"
                        max="100"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        @change="handlePercent"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group :label="$t('Allow to save code before Use Time')" label-cols-md="4">
              <div class="d-flex align-items-center">
                <b-input-group
                  class="input-group-merge"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="CalendarIcon" />
                  </b-input-group-prepend>
                  <flat-pickr
                    v-model="model.early_date"
                    class="form-control"
                    :disabled="model.early_status ? false : true"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:ss',
                    }"
                    placeholder="Choose a date"
                  />
                </b-input-group>
                <b-form-checkbox
                  ref="early_status"
                  v-model="model.early_status"
                  checked="true"
                  class="custom-control-success ml-1"
                  name="check-button"
                  switch
                  :value="1"
                  :unchecked-value="0"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
              <validation-provider
                #default="{ errors }"
                :rules="model.early_status == 1 ? 'required' : ''"
                name="Save early date"
              >
                <input v-model="model.early_date" type="hidden">
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </Fieldset>
          <Fieldset id="couponrole" :title="$t('Advanced Option')">
            <b-form-group :label="$t('Minimum order value')+`:`" label-cols-md="4">
              <validation-provider
                #default="{ errors }"
                :rules="model.is_min_total_price == 1 ? 'required' : ''"
                name="Minimum Requirement"
              >
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="model.min_total_price"
                    :disabled="model.is_min_total_price === 1 ? false : true"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <!-- <cleave
                    v-model="model.min_total_price"
                    :disabled="model.is_min_total_price === 1 ? false : true"
                    class="form-control"
                    :raw="false"
                    :options="cleaveOptions.number"
                  /> -->
                  <b-form-checkbox
                    ref="is_min_total_price"
                    v-model="model.is_min_total_price"
                    checked="true"
                    class="custom-control-success ml-1"
                    name="check-button"
                    switch
                    :value="1"
                    :unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="$t('Minimum product quantity')+`:`" label-cols-md="4">
              <validation-provider
                #default="{ errors }"
                name="Minimum product quantity"
                :rules="model.is_minimum_product_quantity == 1 ? 'required' : ''"
              >
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="model.number_usage_product"
                    :disabled="model.is_minimum_product_quantity === 1 ? false : true"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-checkbox
                    v-model="model.is_minimum_product_quantity"
                    checked="true"
                    class="custom-control-success ml-1"
                    name="check-button"
                    switch
                    :value="1"
                    :unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="$t('Total Number of Coupon')+`:`" label-cols-md="4">
              <validation-provider
                #default="{ errors }"
                name="Total Number of Coupon"
                :rules="model.is_limit_number_coupon == 1 ? 'required' : ''"
              >
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="model.limit_number_coupon"
                    :disabled="model.is_limit_number_coupon === 1 ? false : true"
                    :state="errors.length > 0 ? false : null"
                    type="number"
                  />
                  <b-form-checkbox
                    v-model="model.is_limit_number_coupon"
                    checked="true"
                    class="custom-control-success ml-1"
                    name="check-button"
                    switch
                    :value="1"
                    :unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              :label="$t('Number of Usage per Customer') +`:`"
              label-cols-md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Number of Usage per Customer"
                :rules="model.is_number_usage_customer == 1 ? 'required' : ''"
              >
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="model.number_usage_customer"
                    :disabled="model.is_number_usage_customer === 1 ? false : true"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-checkbox
                    v-model="model.is_number_usage_customer"
                    checked="true"
                    class="custom-control-success ml-1"
                    name="check-button"
                    switch
                    :value="1"
                    :unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="$t('Type of customer') +`:`" label-cols-md="4">
              <validation-provider
                #default="{ errors }"
                name="Type of customer"
                rules="required"
              >
                <div class="d-flex align-items-center">
                  <b-form-radio
                    v-model="model.type_customer"
                    :value="0"
                  >
                    {{ $t('All customers') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="model.type_customer"
                    :value="1"
                    class="ml-1"
                  >
                    {{ $t('New customers') }}
                  </b-form-radio>
                  <b-form-radio
                    v-model="model.type_customer"
                    :value="2"
                    class="ml-1"
                  >
                    {{ $t('Old customers') }}
                  </b-form-radio>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </Fieldset>
          <Fieldset id="apply" :title="$t('Apply For')">
            <b-form-group :label="`${$t('Category Applied')}:`" label-cols-md="4">
              <!-- <div class="d-flex align-items-center">
                <b-form-checkbox
                  value="1"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
                <span>On</span>
              </div> -->
              <!-- <b-form-radio
                  v-model="model.type_combo"
                  :value="0"
                  class="mt-2"
                >
                  {{ $t('All categories') }}
                </b-form-radio> -->
              <b-form-radio
                v-model="model.type_combo"
                :value="1"
                style="margin-top: 8px;"
              >
                {{ $t('Specific category') }}
              </b-form-radio>
              <b-form
                v-if="model.type_combo === 1"
                class="mt-1"
                @submit.prevent="repeatCategory('productCategory')"
              >
                <div
                  v-for="(item, index) in productCategory"
                  :id="String(index)"
                  :key="index"
                  class="d-flex align-items-center mb-1 position-relative"
                >
                  <treeselect
                    v-model="productCategory[index]"
                    :multiple="false"
                    :clearable="true"
                    :searchable="true"
                    :open-on-click="true"
                    :clear-on-select="true"
                    :show-count="true"
                    :options="listCategory"
                    :limit="5"
                    :max-height="400"
                  />
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon rounded-circle ml-2"
                    @click="removeCategory('productCategory',index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
                <b-form-group label-cols-md="12" label="">
                  <div class="fade-browse" @click="repeatCategory('productCategory')">
                    <feather-icon icon="PlusCircleIcon" class="mr-25" />
                    {{ $t('Add options') }}
                  </div>
                </b-form-group>
              </b-form>
            </b-form-group>
            <b-form-group :label="`${$t('Product Applied')}:`" label-cols-md="4">
              <!-- <div class="d-flex align-items-center">
                <b-form-checkbox
                  value="2"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
                <span>On</span>
              </div> -->
              <!-- <b-form-radio
                  v-model="model.type_combo"
                  :value="0"
                  class="mt-2"
                >
                  {{ $t('All products') }}
                </b-form-radio> -->
                <b-form-radio
                  v-model="model.type_combo"
                  :value="2"
                  style="margin-top: 8px;"
                >
                  {{ $t('Specific product') }}
                </b-form-radio>
                <!-- <b-form
                v-if="model.type_combo === 2"
                class="repeater-form mt-1"
                @submit.prevent="repeatCategory('products')"
              >
                <div
                  v-for="(item, index) in products"
                  :id="String(index)"
                  :key="index"
                  class="d-flex align-items-center mb-1 position-relative w-100"
                >
                  <v-select
                    v-if="listProduct"
                    v-model="products[index]"
                    label="name"
                    :reduce="(x) => x.id_product"
                    :options="listProduct"
                    class="w-100"
                  />
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon rounded-circle ml-1"
                    @click="removeCategory('products',index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
                <b-form-group label-cols-md="12" label="">
                  <div class="fade-browse" @click="repeatCategory('products')">
                    <feather-icon icon="PlusCircleIcon" class="mr-25" />
                    {{ $t('Add options') }}
                  </div>
                </b-form-group>
              </b-form> -->
              <b-button
                v-if="model.type_combo === 2"
                v-b-modal.modal-xl
                variant="primary"
                type="button"
                class="mt-1"
              >
                {{ $t('Add product') }}
              </b-button>
              <PopupAddProduct v-model="model" />
              <div v-if="model.type_combo === 2 && model.list_product && model.list_product.length > 0">
                <!-- <CustomTable
                  v-model="model"
                  :items="model.list_product"
                  :fields="fields"
                  :per_page="1000"
                  :paging="false"
                  :add_new_button="false"
                  :sort_button="false"
                /> -->
                <vue-good-table
                  ref="promotion-products"
                  class="custom-good-table mt-2 mx-0"
                  :columns="columns"
                  :rows="model.list_product"
                >
                  <template
                    slot="table-column"
                    slot-scope="props"
                  >
                    <span class="text-nowrap">
                      {{ $t(props.column.label) }}
                    </span>
                  </template>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <!-- Column: Name -->
                    <div
                      v-if="props.column.field === 'fullName'"
                      class="avatar-name mx-auto"
                    >
                      <b-avatar
                        :src="props.row.avatar"
                        size="lg"
                        class="mr-1"
                      />
                      <div class="name">
                        {{ props.row.name }}
                      </div>
                    </div>

                    <!-- Column: Price -->
                    <div
                      v-else-if="props.column.field === 'price'"
                      class="text-center"
                    >
                      {{ props.row.price }}
                    </div>

                    <div
                      v-else-if="props.column.field === 'inventory'"
                      class="text-center"
                    >
                      {{ sumStock(props.row) }}
                    </div>

                    <!-- Column: Status -->
                    <div
                      v-else-if="props.column.field === 'status'"
                      class="text-center"
                    >
                      <b-form-checkbox
                        v-model="props.row.is_combo_active"
                        name="check-button"
                        switch
                        inline
                        class="mx-auto"
                      />
                    </div>

                    <!-- Column: Actions -->
                    <div
                      v-else-if="props.column.field === 'action'"
                      class="text-center"
                    >
                      <b-button
                        variant="flat-danger"
                        class="text-danger btn-icon rounded-circle"
                        @click="handlerDelete(props.row.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </div>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['10','30','50']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </b-form-group>

            <!-- <b-form-group :label="`${$t('Combo Applied')}:`" label-cols-md="4">
              <div class="d-flex align-items-center">
                <b-form-checkbox
                  v-model="model.type_combo"
                  value="3"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                  style="margin-top: 8px;"
                />
                <span>On</span>
              </div>
              <b-form v-if="model.type_combo == 3" class="repeater-form mt-1" @submit.prevent="repeatVariation">
                <div
                  v-for="(item, index) in variations"
                  :id="String(index)"
                  :key="index"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="bg-gray w-100">
                    <b-form-group :label="`Combo ${index + 1}`" label-cols-md="12">
                      <b-form-input
                        v-model="variations[index].combo_name"
                        placeholder="Enter Combo Name"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="
                        variations &&
                          variations[index] &&
                          variations[index]['list_product']
                      "
                      label="Product"
                      label-cols-md="12"
                    >
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatOption(index)"
                      >
                        <div
                          v-for="(i, idx) in variations[index]['list_product']"
                          :id="String(idx)"
                          :key="idx"
                          class="d-flex align-items-center mb-1 position-relative"
                        >
                          <v-select
                            v-if="listProduct"
                            v-model="variations[index]['list_product'][idx]"
                            label="name"
                            :reduce="(x) => x.id_product"
                            :options="listProduct"
                            class="w-100"
                          />
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-danger"
                            class="btn-icon rounded-circle ml-1"
                            @click="removeOption(index, idx)"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </div>
                      </b-form>
                    </b-form-group>

                    <b-form-group label-cols-md="12" label="">
                      <div class="fade-browse" @click="repeatOption(index)">
                        <feather-icon icon="PlusCircleIcon" class="mr-25" />
                        {{ $t('Add options') }}
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </b-form>
              <div v-if="model.type_combo == 3" class="fade-browse mt-1" @click="repeatVariation">
                <feather-icon icon="PlusCircleIcon" class="mr-25" />
                {{ $t('Add variations') }}
              </div>
            </b-form-group> -->

            <!-- <hr/>
            <b-form-group label="Type combo:" label-cols-md="4">
              <div class="d-flex align-items-center mt-1">
                <b-form-radio
                  v-model="model.type_combo"
                  :value="0"
                >
                  {{ $t('All product') }}
                </b-form-radio>
                <b-form-radio
                  v-model="model.type_combo"
                  :value="1"
                  class="ml-3"
                >
                  {{ $t('Category Applied') }}
                </b-form-radio>
                <b-form-radio
                  v-model="model.type_combo"
                  :value="2"
                  class="ml-3"
                >
                  {{ $t('Product Applied') }}
                </b-form-radio>
                <b-form-radio
                  v-model="model.type_combo"
                  :value="3"
                  class="ml-3"
                >
                  {{ $t('Combo Applied') }}
                </b-form-radio>
              </div>
              <b-form
                v-if="model.type_combo === 1"
                class="repeater-form mt-1"
                @submit.prevent="repeatCategory('productCategory')"
              >
                <div
                  v-for="(item, index) in productCategory"
                  :id="String(index)"
                  :key="index"
                  class="d-flex align-items-center mb-1 position-relative"
                >
                  <treeselect
                    v-model="productCategory[index]"
                    :multiple="false"
                    :clearable="true"
                    :searchable="true"
                    :open-on-click="true"
                    :clear-on-select="true"
                    :show-count="true"
                    :options="listCategory"
                    :limit="5"
                    :max-height="400"
                  />
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon rounded-circle ml-2"
                    @click="removeCategory('productCategory',index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
                <b-form-group label-cols-md="12" label="">
                  <div class="fade-browse" @click="repeatCategory('productCategory')">
                    <feather-icon icon="PlusCircleIcon" class="mr-25" />
                    {{ $t('Add options') }}
                  </div>
                </b-form-group>
              </b-form>
              <b-form
                v-if="model.type_combo === 2"
                class="repeater-form mt-1"
                @submit.prevent="repeatCategory('products')"
              >
                <div
                  v-for="(item, index) in products"
                  :id="String(index)"
                  :key="index"
                  class="d-flex align-items-center mb-1 position-relative w-100"
                >
                  <v-select
                    v-if="listProduct"
                    v-model="products[index]"
                    label="name"
                    :reduce="(x) => x.id_product"
                    :options="listProduct"
                    class="w-100"
                  />
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon rounded-circle ml-1"
                    @click="removeCategory('products',index)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
                <b-form-group label-cols-md="12" label="">
                  <div class="fade-browse" @click="repeatCategory('products')">
                    <feather-icon icon="PlusCircleIcon" class="mr-25" />
                    {{ $t('Add options') }}
                  </div>
                </b-form-group>
              </b-form>
              <b-form v-if="model.type_combo === 3" class="repeater-form mt-1" @submit.prevent="repeatVariation">
                <div
                  v-for="(item, index) in variations"
                  :id="String(index)"
                  :key="index"
                  class="d-flex align-items-center mb-1"
                >
                  <div class="bg-gray w-100">
                    <b-form-group :label="`Combo ${index + 1}`" label-cols-md="12">
                      <b-form-input
                        v-model="variations[index].combo_name"
                        placeholder="Enter Combo Name"
                      />
                    </b-form-group>
                    <b-form-group
                      v-if="
                        variations &&
                          variations[index] &&
                          variations[index]['list_product']
                      "
                      label="Product"
                      label-cols-md="12"
                    >
                      <b-form
                        class="repeater-form"
                        @submit.prevent="repeatOption(index)"
                      >
                        <div
                          v-for="(i, idx) in variations[index]['list_product']"
                          :id="String(idx)"
                          :key="idx"
                          class="d-flex align-items-center mb-1 position-relative"
                        >
                          <v-select
                            v-if="listProduct"
                            v-model="variations[index]['list_product'][idx]"
                            label="name"
                            :reduce="(x) => x.id_product"
                            :options="listProduct"
                            class="w-100"
                          />
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-danger"
                            class="btn-icon rounded-circle ml-1"
                            @click="removeOption(index, idx)"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </div>
                      </b-form>
                    </b-form-group>

                    <b-form-group label-cols-md="12" label="">
                      <div class="fade-browse" @click="repeatOption(index)">
                        <feather-icon icon="PlusCircleIcon" class="mr-25" />
                        {{ $t('Add options') }}
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </b-form>
              <div v-if="model.type_combo === 3" class="fade-browse mt-1" @click="repeatVariation">
                <feather-icon icon="PlusCircleIcon" class="mr-25" />
                {{ $t('Add variations') }}
              </div>
            </b-form-group> -->
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="status" :title="$t('Status')">
              <b-form-group :label="$t('Coupon status') +`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_active"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group>
              <!-- <b-form-group :label="$t('Public the Coupon') +`:`" label-cols-md="6">
                <b-form-checkbox
                  v-model="model.is_public"
                  checked="true"
                  class="custom-control-success"
                  name="check-button"
                  switch
                />
              </b-form-group> -->
              <hr>
              <!-- Submit -->
              <div class="text-right">
                <b-button
                  variant="primary"
                  type="submit"
                  class="text-right"
                  :disabled="model.status == 'Upcoming' ? false : true"
                  @click.prevent="validationForm"
                >
                  {{ $t('Update') }}
                </b-button>
              </div>
            </Fieldset>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
/* eslint-disable prefer-destructuring */
/* eslint-disable object-shorthand */
/* eslint-disable array-callback-return */

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadio,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Treeselect from '@riophae/vue-treeselect'
import { VueGoodTable } from 'vue-good-table'
import PopupAddProduct from './components/PopupAddProduct.vue'

extend('max', {
  validate(value, args) {
    return value.length <= args.length
  },
  params: ['length'],
})

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BFormCheckbox,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    Treeselect,
    // vSelect,
    PopupAddProduct,
    VueGoodTable,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      required,
      productCategory: [],
      listCategory: [],
      listProduct: [],
      products: [],
      variations: [],
      currencyDefault: [],
      currencyOptionsDefault: [],
      currencyOptions: [],
      currencyJson: {
        currency: [],
      },
      model: {
        list_product: [],
      },
      fields: [
        { key: 'no', label: 'No', sortable: true },
        { key: 'avatar', label: 'Avatar', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        // { key: 'sku', label: 'SKU', sortable: true },
        { key: 'price', label: 'Price', sortable: true },
        { key: 'more', label: 'Tool', sortable: false },
      ],
      columns: [
        {
          label: 'Product',
          field: 'fullName',
        },
        {
          label: 'Price',
          field: 'price',
        },
        // {
        //   label: 'Inventory',
        //   field: 'inventory',
        // },
        // {
        //   label: 'Status',
        //   field: 'status',
        // },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
    }
  },
  watch: {
    'model.code_type'() {
      if (this.model.code_type === 1) {
        this.model.code = this.generateCode(6)
      }
      // else {
      //   this.model.code = null
      // }
    },
  },
  async mounted() {
    // this.model.code = this.generateCode(6)
    this.loadCategoryList()
    this.loadProduct()
    this.loadDetail()

    this.currencyJson.currency = await this.loadCurrencyList()
    this.currencyList = await this.loadCurrencyList()
    // this.setCurrency()
    let currCurrency = null
    const curr = JSON.parse(localStorage.getItem('siteID')).currency !== null ? JSON.parse(localStorage.getItem('siteID')).currency : 'VND'
    currCurrency = this.currencyJson.currency.filter(
      x => x.iso_code === curr,
    )
    currCurrency[0].is_active = true
    this.currencyDefault = currCurrency[0]
    this.currencyOptions = await this.loadcurrency()
  },
  methods: {
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              is_public: Number(this.model.is_public === true ? 1 : 0),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              name: this.model.name,
              code: this.model.code,
              type: this.model.type,
              type_combo: String(this.model.type_combo),
              type_customer: String(this.model.type_customer),
              early_status: this.model.early_status,
            }
            if (this.model.date_from !== null) {
              params.date_from = this.model.date_from
            }
            if (this.model.date_to !== null) {
              params.date_to = this.model.date_to
            }
            if (this.model.early_date !== null) {
              params.early_date = this.model.early_date
            }
            if (this.model.discount_money) {
              params.discount_money = this.model.discount_money
            }
            if (this.model.is_limit_number_coupon === 1) {
              params.limit_number_coupon = this.model.limit_number_coupon
            } else {
              params.limit_number_coupon = 0
            }
            if (this.model.is_number_usage_customer === 1) {
              params.number_usage_customer = this.model.number_usage_customer
            } else {
              params.number_usage_customer = 0
            }
            if (this.model.is_minimum_product_quantity === 1) {
              params.number_usage_product = this.model.number_usage_product
            } else {
              params.number_usage_product = 0
            }
            if (this.model.is_min_total_price === 1) {
              params.min_total_price = this.model.min_total_price
            } else {
              params.min_total_price = 0
            }

            if (this.model.type_combo === 1) {
              this.model.list_combo = this.productCategory.join(',')
              params.list_combo = this.model.list_combo
            }
            if (this.model.type_combo === 2) {
              // this.model.list_combo = this.products.join(',')
              // params.list_combo = this.model.list_combo
              const temp = []
              this.model.list_product.map(val => {
                temp.push(val.id)
              })
              params.list_combo = temp.join(',')
            }
            if (this.model.type_combo === 3) {
              this.model.list_combo = {}
              this.variations.map((val, index) => {
                this.model.list_combo[`combo_${index + 1}`] = val.list_product.join(',')
              })
              params.list_combo = JSON.stringify(this.model.list_combo)
            }
            // console.log(params.list_combo)
            const res = await Request.put(
              this.$http,
              `${process.env.VUE_APP_API_URL}/v2/coupon/${this.$route.params.id}`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Coupon success',
                  },
                })
                this.$router.push('/coupon/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    handlePercent() {
      if (this.model.discount_money < 0) {
        this.model.discount_money = 0
      }
      if (this.model.discount_money > 100) {
        this.model.discount_money = 100
      }
    },
    handleCode() {
      if (this.model.code.length > 6) {
        this.model.code = this.model.code.slice(-6)
      }
    },
    repeatCategory(elm) {
      this[elm].push(null)
    },
    async removeCategory(elm, index) {
      this[elm].splice(index, 1)
    },
    async loadCategoryList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (result.data.status) {
            this.listCategory = []
            const res = result.data.data
            res.map(x => {
              const item = {
                id: x.id_category,
                label: x.name,
                slug: x.slug,
                idx: x.id_category,
                children: [],
              }
              if (x.children && x.children.length > 0) {
                x.children.map(y => {
                  const xitem = {
                    id: y.id_category,
                    label: y.name,
                    slug: y.slug,
                    idx: y.id_category,
                    children: [],
                    parent_id: x.id_category,
                  }
                  if (y.children && y.children.length > 0) {
                    y.children.map(z => {
                      const zitem = {
                        id: z.id_category,
                        label: z.name,
                        slug: z.slug,
                        idx: z.id_category,
                        parent_id: y.id_category,
                        root_id: x.id_category,
                      }
                      xitem.children.push(zitem)
                    })
                  }
                  item.children.push(xitem)
                })
              }
              this.listCategory.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(result.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadProduct() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.listProduct = res.data.data.items
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeatVariation() {
      this.variations.push({
        combo_name: null,
        list_product: [],
      })
    },
    repeatOption(index) {
      this.variations[index].list_product.push(null)
    },
    async removeOption(parentIndex, index) {
      this.variations[parentIndex].list_product.splice(index, 1)
      if (this.variations[parentIndex].list_product.length === 0) {
        this.variations.splice(parentIndex, 1)
      }
    },
    async loadDetail() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/v2/coupon/${this.$route.params.id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model = {
              ...res.data.data,
              ...this.model,
              code_type: 2,
              date_from_to: `${res.data.data.date_from} to ${res.data.data.date_to}`,
              date_from: `${res.data.data.date_from}`,
              date_to: `${res.data.data.date_to}`,
              is_min_total_price: res.data.data.min_total_price === 0 ? 0 : 1,
              is_limit_number_coupon: res.data.data.limit_number_coupon === 0 ? 0 : 1,
              is_minimum_product_quantity: res.data.data.number_usage_product === 0 ? 0 : 1,
              is_number_usage_customer: res.data.data.number_usage_customer === 0 ? 0 : 1,
              type_customer: res.data.data.type_customer,
              is_public: Boolean(res.data.data.is_public === 1),
              is_active: Boolean(res.data.data.is_active === 1),
              list_id_product: [],
            }
            if (res.data.data.list_combo.length !== undefined) {
              if (res.data.data.type_combo === 1) {
                res.data.data.list_combo.map(val => {
                  this.productCategory.push(val.id)
                })
              }
              if (res.data.data.type_combo === 2) {
                res.data.data.list_combo.map(item => {
                  this.model.list_id_product.push(item.id)
                  return true
                })
                res.data.data.list_combo.map(val => {
                  this.model.list_product.push(val)
                })
              }
            } else {
              for (let i = 0; i < Object.keys(res.data.data.list_combo).length; i += 1) {
                const item = {
                  combo_name: `Combo ${i + 1}`,
                  list_product: [],
                }
                res.data.data.list_combo[`combo_${i + 1}`].map(val => item.list_product.push(val.id))
                this.variations.push(item)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadCurrencyList() {
      const list = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_CLIENT_API_URL}/platform_currency`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            res.data.data.items.map(val => {
              const item = {
                img: val.avatar,
                iso_code: val.iso_code,
                name: val.name,
                symbol: '',
              }
              list.push(item)
              return item
            })
            return list
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(this.showError(res.data.error, ',')),
            },
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return list
    },
    async loadcurrency() {
      this.isShow = true
      const currencyOptions = []
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/currencies?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          this.isShow = false
          if (res.data.status) {
            this.currencyOptionsDefault = []
            this.listcurrencyLength = res.data.data.items.length
            res.data.data.items.map(val => {
              const currencyFil = this.currencyJson.currency.filter(
                x => x.iso_code === val.code,
              )
              currencyFil[0].is_active = Boolean(val.is_active === 1)
              currencyFil[0].display_name = `${currencyFil[0].iso_code} (${currencyFil[0].name})`
              currencyFil[0].exchange = val.exchange
              currencyFil[0].origin = val.origin
              if (val.is_active !== 0) {
                this.currencyOptionsDefault.push(currencyFil[0])
              }
              currencyFil[0].id = val.id
              return currencyOptions.push(currencyFil[0])
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      return currencyOptions
    },
    handlerDelete(id) {
      this.model.list_product.splice(this.model.list_product.findIndex(val => val.id === id), 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.inline-block {
  display: block;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
.text-1 {
  font-size: 1rem;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
